import { SectionHeadingComponent } from './Margins';
import Calendar from '@ericz1803/react-google-calendar';

export interface CalendarProps {
    calendarId: string;
}

export function CalendrierSectionComponent(props: CalendarProps) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const firstYear = currentMonth < 8 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    const secondYear = currentMonth < 8 ? currentDate.getFullYear() : currentDate.getFullYear() + 1;
    return (
        <div className="container">
            <SectionHeadingComponent
                headingTitle={'Calendrier'}
                headingContent={
                    <>
                        Voici notre calendrier d'évènements pour l'année {firstYear}-{secondYear}
                    </>
                }
            />
            <div className="row">
                <Calendar
                    apiKey={process.env.REACT_APP_CALENDAR_API_KEY}
                    calendars={[{ calendarId: props.calendarId }]}
                    language={'FR'}
                />
            </div>
        </div>
    );
}
