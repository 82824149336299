import { SectionHeadingComponent } from './Margins';
import { FaCalendar, FaCoffee, FaCouch, FaLaptop, FaList, FaTshirt } from 'react-icons/fa';
import { MultiLineStringComponent } from './Text';

export interface ServiceProps {
    serviceIcon: JSX.Element;
    serviceHeading: JSX.Element;
    serviceText: JSX.Element;
}

export function ServiceComponent(props: ServiceProps) {
    return (
        <div className="col-md-4">
            <span className="service-icon">{props.serviceIcon}</span>
            <h4 className="service-heading">{props.serviceHeading}</h4>
            <p className="text-muted">{props.serviceText}</p>
        </div>
    );
}

export interface ServiceSectionProps {
    programName: string;
    sisterTitle: string;
    plaintesAEP: string;
    swagEnabled: boolean;
}

export function ServiceSectionComponent(props: ServiceSectionProps) {
    const serviceIconStyle = { color: 'black' };
    return (
        <div className="container">
            <SectionHeadingComponent
                headingTitle={'Services'}
                headingContent={
                    <>
                        En tant que comité étudiant, nous offrons plusieurs services aux étudiants du génie{' '}
                        {props.programName}
                    </>
                }
            />
            <div className="row text-center">
                <ServiceComponent
                    serviceIcon={<FaCalendar style={serviceIconStyle} />}
                    serviceHeading={
                        <MultiLineStringComponent text={"Organisation\nd'évènements"} key={'organisation'} />
                    } // eslint-disable-line
                    serviceText={
                        <>
                            Nous organisons divers évènements de cohésion entre les étudiants du génie, en collaboration
                            avec le {props.sisterTitle}, ainsi que de multiples évènements de réseautage tout au long de
                            l'année. Pour plus de détails, consultez l'onglet{' '}
                            <a className="js-scroll-trigger" href="#calendar">
                                Calendrier
                            </a>
                            .
                        </>
                    }
                />
                <ServiceComponent
                    serviceIcon={<FaList style={serviceIconStyle} />}
                    serviceHeading={
                        <MultiLineStringComponent text={"Formulaires de plaintes\nà l'éducation"} key={'plaintes'} />
                    }
                    serviceText={
                        <>
                            Si vous considérez que la qualité de l'enseignement reçu dans le cadre d'un de vos cours
                            n'est pas à la hauteur de vos attentes, vous pouvez soumettre une plainte anonyme à notre VP
                            Éducation pour que votre voix soit entendue. Voici le{' '}
                            <a href={props.plaintesAEP} target="_blank" rel="noopener noreferrer">
                                formulaire{' '}
                            </a>
                            en question.
                        </>
                    }
                />
                <ServiceComponent
                    serviceIcon={<FaCoffee style={serviceIconStyle} />}
                    serviceHeading={<MultiLineStringComponent text={'Collations à\n petit prix'} key={'collations'} />}
                    serviceText={
                        <>
                            Cafés, pogos, Arizona, barres tendres et plus encore; des collations à petit prix sont
                            offertes au local du comité.
                        </>
                    }
                />
                <ServiceComponent
                    serviceIcon={<FaCouch style={serviceIconStyle} />}
                    serviceHeading={<MultiLineStringComponent text={"Local d'échange\net de détente"} key={'local'} />}
                    serviceText={
                        <>
                            Venez au M-4521 profiter des micro-ondes, échanger sur vos divers travaux ou simplement
                            écouter de la musique. Notre local étudiant est le meilleur endroit pour apprendre à
                            connaître les autres étudiants en informatique et logiciel.
                        </>
                    }
                />
                <ServiceComponent
                    serviceIcon={<FaTshirt style={serviceIconStyle} />}
                    serviceHeading={
                        <MultiLineStringComponent
                            text={'Vente de vêtements\n à thématique informatique'}
                            key={'swag'}
                        />
                    }
                    serviceText={
                        <>
                            Une fois par année, les membres de l'exécutif se rassemblent pour créer une nouvelle
                            collection unique de t-shirts, crewnecks, hoodies et sweatpants. Suivez-nous sur Facebook
                            pour ne pas manquer la période de commande!
                            {props.swagEnabled && (
                                <>
                                    {' '}
                                    <a href="/swag">Faites votre commande ici</a>.
                                </>
                            )}
                        </>
                    }
                />
                <ServiceComponent
                    serviceIcon={<FaLaptop style={serviceIconStyle} />}
                    serviceHeading={<MultiLineStringComponent text={"SÉPIL\nSoirée d'étude"} key={'sepil'} />}
                    serviceText={
                        <>
                            Les études c'est important, mais les faire seul c'est parfois déprimant. On vous invite donc
                            aux SÉPIL, les séances d'étude pour info-log, où vous pourrez travailler sur vos projets
                            tout en mangeant de la pizza gratuite accompagnée d'un bon café.
                        </>
                    }
                />
            </div>
        </div>
    );
}
