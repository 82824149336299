import { FaBars } from 'react-icons/fa';

export interface NavigationItemProps {
    anchorName: string;
    anchorAffichableName?: string;
    condition?: boolean;
}

export function NavigationItemComponent({
    anchorName,
    anchorAffichableName = undefined,
    condition = true,
}: NavigationItemProps): JSX.Element {
    return condition ? (
        <li className="nav-item">
            <a className="nav-link js-scroll-trigger" href={`#${anchorName.toLowerCase()}`}>
                {anchorAffichableName !== undefined ? anchorAffichableName : anchorName}
            </a>
        </li>
    ) : (
        <></>
    );
}

export interface NavigationHeaderProps {
    title: string;
    menuHref: string;
    anchors: Array<JSX.Element>;
}

export function NavigationHeaderComponent(props: NavigationHeaderProps) {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div className="container">
                <a className="navbar-brand js-scroll-trigger" href={props.menuHref}>
                    {props.title}
                </a>
                <button
                    className="navbar-toggler navbar-toggler-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    Menu
                    <i>
                        {' '}
                        <FaBars />
                    </i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav text-uppercase ml-auto">
                        {props.anchors.map((value: JSX.Element) => value)}
                    </ul>
                </div>
            </div>
        </nav>
    );
}
