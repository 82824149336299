import { Team, TeamMember } from '../interfaces/config';
import { SectionHeadingComponent } from './Margins';
import { TeamMemberComponent } from './Member';

export interface TeamSectionProps {
    team: Team<TeamMember>;
    programName: string;
    genieName: string;
}

export function TeamSectionComponent(props: TeamSectionProps) {
    return (
        <div className="container">
            <SectionHeadingComponent
                headingTitle={'Notre équipe exécutive'}
                headingContent={
                    <>Dédiés à votre vie étudiante, voici les visages derrière le comité de génie {props.programName}</>
                }
            />
            <div className="row">
                {Object.entries(props.team).map(([roleName, teamMember]: [string, TeamMember]) => (
                    <TeamMemberComponent
                        key={roleName}
                        roleName={roleName}
                        teamMember={teamMember}
                        genieName={props.genieName}
                    />
                ))}
            </div>
        </div>
    );
}
